<template>
    <div v-if="this.dragMatch" :class="(inDrag ? 'in-drag ' : ' ') + 'drag-target'"
        @dragover="handleDragOver" @dragenter="handleDragEnter" @dragleave="handleDragLeave">
        <div @drop="handleDrop" @dragover.prevent @dragenter.prevent>
            <slot></slot>
        </div>
    </div>
    <div v-else-if="this.expand">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'DragTarget',
    props: {
        expand: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String,
            default: 'section'
        }
    },
    data: () => ({
        inDrag: false
    }),
    computed: {
        dragMatch() {
            if (this.$store.state.dragging) {
                return this.$store.state.dragging.match(this.mask);
            }
        },
    },
    methods: {
        handleDragEnter() {
            this.inDrag = true;
        },
        handleDrop() {
            this.inDrag = false;
        },
        handleDragLeave() {
            this.inDrag = false;
        },
        handleDragOver() {
            this.inDrag = true;
        },
    }
}
</script>

<style scoped>
.drag-target {
    font-size: 0.5em;
    border: 1px dashed var(--app-color-amanda-green-4);
    background-color: var(--app-color-persimmon-7a50);
}

.in-drag {
    background-color: var(--app-color-amanda-green-7a25);
}
</style>
