<script>
export default {
    name: 'UiButton',
};
</script>

<template>
    <amplify-button>
        <slot></slot>
    </amplify-button>
</template>

<style scoped>
button {
    font-size: 16px;
    padding: 0.75em 1em;
    border-radius: 0.5em;
    border: 1px solid rgb(174, 179, 183);
    background-color: white;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
}
</style>
