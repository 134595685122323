<template>
    <Page>
        <div class="content-padding" v-if="!isSignedIn">
            <amplify-authenticator>
                <template v-slot="{ user, signOut }">
                    {{ user.username }}
                    <amplify-button @click="signOut">Sign Out</amplify-button>
                </template>
            </amplify-authenticator>
        </div>
        <div class="content-padding" v-else></div>
    </Page>
</template>

<script>
import Page from "../layout/Page.vue";
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { Environment } from '@common';

const { STACK_NAME, API_GATEWAY_URL } = Environment;

export default {
    name: "SignInPage",
    components: {
        Page,
    },
    data: function () {
        return {
            auth: useAuthenticator(),
        };
    },
    mounted() {
        if (this.$store.state.authentication.isSignedIn) {
            this.$router.push('/');
        }
    },
    computed: {
        API_GATEWAY_URL: function () {
            return API_GATEWAY_URL;
        },
        STACK_NAME: function () {
            return STACK_NAME;
        },
        username: function () {
            return this.$store.state?.authentication?.username;
        },
        isSignedIn: function () {
            return this.$store.state?.authentication?.isSignedIn;
        },
    },
};
</script>

<style>
.content-padding {
    margin-top: 1em;
}

.sign-out-container {
    display: flex;
    justify-content: center;
}
</style>
