<template>
    <div :class="this.direction + (this.open ? ' open' : ' close')"
        :style="`${this.horizontal}; width: ${this.width}`">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'UiSlideOut',
    props: {
        open: {
            type: Boolean,
            default: true
        },
        direction: {
            type: String,
            default: 'right'
        },
        width: {
            type: String,
            default: '300px'
        },
        offset: {
            type: String,
            default: '0px'
        },
        zIndex: {
            type: Number,
            default: 1000
        }
    },
    computed: {
        horizontal() {
            return `${this.direction}: ${this.offset}`;
        },
    }
};
</script>

<style scoped>
div {
    background: white;
    position: fixed;
    padding-top: 75px;
    top: 0;
    height: 100vh;
    transition: margin 300ms;
    margin: 0;
}

div.right {
    right: 0;
    box-shadow: -6px 0 6px rgba(0, 0, 0, 0.1);
}

div.left {
    left: 0;
    box-shadow: +6px 0 6px rgba(0, 0, 0, 0.1);
}

div.right.close {
    margin-right: -100%;
}

div.left.close {
    margin-left: -100%;
}
</style>
