<template>
    <template v-if="mutable && this.inEdit">
        <div v-if="this.inEdit">
            <editor ref="editor"
                v-on:blur="saveHandler"
                v-model="data.value" api-key="vt72hvk265pc49kek0ktl1sw89xku9asmg2436kfcvzxnic6"
                @dirty="dirtyHandler" output-format="html" :init="{
                    plugins: ['autoresize', 'autolink', 'link', 'lists', 'media', 'table', 'image', 'codesample', 'hr', 'help'],
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | numlist bullist | forecolor backcolor removeformat',
                    menubar: false,
                    statusbar: false,
                    inline: true,
                    toolbar_mode: 'floating',
                    toolbar_sticky: true,
                    toolbar_persest: true,
                    toolbar_location: 'bottom',
                    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    // quickbars_insert_toolbar: 'quicktable image media codesample | hr',
                    // quickbars_image_toolbar: 'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
                    // contextmenu: 'undo redo | inserttable | cell row column deletetable | help',
                    contextmenu: false,
                    // contextmenu_never_use_native: true,
                    noneditable_noneditable_class: 'mceNonEditable', //nice to be able to insert non-editable regions into segments/templates
                    width: '100%',
                }" />
            <!-- <ui-button @click="saveHandler">Save</ui-button> -->
        </div>
    </template>
    <template v-else>
        <div v-html="this.data.value" @click="edit" :class="mutable ? 'editable' : ''"></div>
    </template>
</template>

<script>
import UiButton from "../ui/UiButton.vue";
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "TextField",
    components: {
        UiButton,
        Editor,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: { value: "" },
        },
        mutable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["field-update"],
    data() {
        return {
            inEdit: true,
        };
    },
    methods: {
        edit() {
            console.log("TextField.vue edit2()")
            this.inEdit = true;
            this.dirty = false;
        },
        dirtyHandler() {
            this.dirty = true;
        },
        saveHandler() {
            if (this.dirty) {
                this.$emit("field-update", {
                    data: {
                        value: this.data.value
                    },
                });
            } else {
                console.log("editor is not dirty, not saving");
            }
            this.inEdit = true;
            this.dirty = false;
        }
    }
};
</script>

<style scoped>
.editable {
    cursor: pointer;
}

button {
    margin-top: 1em;
}
div {
    border: 1px solid blue;
}
</style>
