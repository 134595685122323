<template>
    <div class="root">
        <ui-logo :height="44" @click="gotoHome" />
        <div class="navigation">
            <ul v-if="isSignedIn">
                <li v-for="page in pages" :key="page.name">
                    <span class="current" v-if="page.urls.includes(this.$route.path)">
                        <router-link class="active" :to="page.urls[0]">
                            <font-awesome-icon size="lg" :icon="page.icon" />
                            {{ page.name }}
                        </router-link>
                    </span>
                    <router-link class="active" v-else :to="page.urls[0]">
                        <font-awesome-icon size="lg" :icon="page.icon" />
                        {{ page.name }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="right">
            <div v-if="isSignedIn">
                <span class="username">{{ username }}</span>
                <amplify-button @click="signOut">Sign Out</amplify-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
    name: "Header",
    methods: {
        gotoHome: function () {
            this.$router.push("/");
        },
        signOut: function () {
            Auth.signOut();
        },
    },
    computed: {
        username: function () {
            return this.$store.state?.authentication?.username;
        },
        isSignedIn: function () {
            return this.$store.state?.authentication?.isSignedIn;
        },
        pages: {
            get() {
                return [
                    {
                        name: "Articles",
                        icon: "books",
                        urls: ["/", "/articles"],
                    },
                    {
                        name: "Templates",
                        icon: "shapes",
                        urls: ["/templates"],
                    },
                    {
                        name: "Assets",
                        icon: "photo-film",
                        urls: ["/assets"],
                    },
                ];
            },
        },
    },
};
</script>

<style scoped>
.root {
    display: flex;
    text-align: left;
    padding: var(--app-distance-050) var(--app-distance-100);
    border-bottom: 1px solid #ddd;
    align-items: center;
    z-index: 100;
    background: #fff;
    color: var(--app-color-slate-3);
}
.navigation {
    flex: 2;
    text-align: left;
}
.navigation ul {
    margin: 0 0 0 1em;
    padding: 0;
}
.navigation ul li {
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: var(--app-distance-100) var(--app-distance-150);
}
.right {
    flex-shrink: 1;
    text-align: right;
    padding: var(--app-distance-050) var(--app-distance-100);
}
.right > * {
    display: inline;
    margin-left: var(--app-distance-100);
}
.username {
    margin-right: 1em;
}
.navigation a {
    text-decoration: none;
    color: var(--app-color-afternoon-skyblue-5);
    color: var(--app-color-slate-7);
}
.navigation .current a {
    text-decoration: none;
    color: var(--app-color-afternoon-skyblue-5);
}
.current {
    padding-bottom: var(--app-distance-050);
    border-bottom: var(--app-distance-012) solid var(--app-color-white);
}
</style>
