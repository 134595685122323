<template>
    <div
        :data-component="this.$options.name"
        :class="'bag' + (this.mutable ? ' mutable' : ' ')"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave">
        <div
            v-if="!this.dragging && this.inSelect" 
            class="tools">
            <span
                draggable="true"
                v-on:dragstart="onMoveStart"
                v-on:dragend="onDragEnd">
                <font-awesome-icon icon="arrows-up-down-left-right" />
            </span>
            <span
                draggable="true"
                v-on:dragstart="onCloneStart"
                v-on:dragend="onDragEnd">
                <font-awesome-icon icon="clone" />
            </span>
            <span @click.stop="onFieldDelete">
                <font-awesome-icon icon="trash" />
            </span>
            <span @click.stop="onElevate">
                <font-awesome-icon icon="arrow-turn-up" />
            </span>
        </div>
        <component
            v-bind="this.model.properties"
            :inDrag="!!(this.dragging)"
            :mutable="this.inSelect && this.mutable"
            :class="'recursive-node' + (this.inHover ? ' in-hover' : '') + (this.inSelect ? ' in-select' : '')"
            :is="this.model.type"
            :columns="this?.model?.children?.length ?? 1"
            @click="onComponentClick"
            @field-update="onFieldUpdate">
            <drag-target
                v-if="!this.inDrag"
                :dragging="this.dragging"
                dragMask=".*"
                @dragenter.prevent
                @dragover.prevent
                @drop="(event) => onDrop(event, 0)">&nbsp;</drag-target>
                <div v-if="this.model.children && this.model.children == 0" class="placeholder">&nbsp;</div>
            <template
                v-if="!this.model?.properties?.innerHTML"
                v-for="(element, index) in this.model.children"
                :key="index">
                <recursive-render
                    :dragging="this.inDrag ? undefined : this.dragging"
                    :mutable="true"
                    :depth="depth + 1"
                    :model="element"
                    :index="index"
                    @child-select="onChildSelect"
                    @child-elevate="onChildElevate"
                    @child-hover="onMouseLeave"
                    @child-drag="onChildDrag"
                    @child-delete="onChildDelete"
                    @child-update="onChildUpdate">
                </recursive-render>
            <drag-target
                v-if="!this.inDrag"
                :dragging="this.dragging"
                dragMask=".*"
                @dragenter.prevent
                @dragover.prevent
                @drop="(event) => onDrop(event, index + 1)">&nbsp;</drag-target>
            </template>
        </component>
    </div>
</template>

<script>
import DragTarget from "./DragTarget.vue";
import HeadingField from "./HeadingField.vue";
import TextField from "./TextField.vue";
import ColumnField from "./ColumnField.vue";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowsUpDownLeftRight as faArrowsUpDownLeftRightRegular,
    faClone as faClone,
    faTrash as faTrash,
} from '@fortawesome/pro-solid-svg-icons';
library.add(
    faArrowsUpDownLeftRightRegular,
    faClone,
    faTrash,
);

export default {
    name: "RecursiveRender",
    components: {
        DragTarget,
        HeadingField,
        TextField,
        FontAwesomeIcon,
        ColumnField,
    },
    props: {
        depth: {
            type: Number,
            default: 0,
        },
        dragging: {
            type: String,
            default: undefined,
        },
        index: {
            type: Number,
        },
        model: {
            type: Object,
            default: () => ({}),
        },
        mutable: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        "child-delete",
        "child-drag",
        "child-elevate",
        "child-hover",
        "child-select",
        "child-update",
    ],
    data: () => ({
        inHover: false,
        inDrag: false,
        inSelect: false,
    }),
    methods: {
        onChildSelect(event) { // bubble
            this.$emit("child-select", event);
        },
        enableSelect() {
            if (window.kbClearSelect) {
                window.kbClearSelect();
            }
            this.inSelect = true;
            this.$emit("child-select", {
                data: this.model,
                updateProperty: (key, value) => {
                    this.model.properties[key] = value;
                },
                cancelSelection: () => {
                    console.debug("RR:cancelSelection");
                    this.inSelect = false;
                },
            });
            window.kbClearSelect = () => {
                this.inSelect = false;
                window.kbClearSelect = undefined;
            };
        },
        onComponentClick(event) {
            event.stopPropagation();
            this.enableSelect();
            console.log("app-common RecursiveRender onComponentClick(event)")
            console.log("app-common this.mutable:", this.mutable)
        },
        onElevate() {
            this.inSelect = false;
            this.$emit("child-elevate");
        },
        onChildElevate() {
            this.enableSelect()
        },
        onMouseOver(event) {
            event.stopPropagation();
            this.inHover = true;
            this.$emit("child-hover", false);
        },
        onMouseLeave() {
            this.inHover = false;
            this.$emit("child-hover", false);
        },
        onFieldDelete() {
            this.$emit("child-delete", { data: this.index });
        },
        onFieldUpdate(event) {
            const nextModel = { ...this.model };
            nextModel.properties.data = event.data;
            this.$emit("child-update", { data: nextModel });
        },
        onChildDelete(event) {
            const index = event.data;
            const nextModel = { ...this.model };
            nextModel.children.splice(index, 1);
            this.$emit("child-update", { data: nextModel });
        },
        onChildUpdate(event, index) {
            const nextModel = { ...this.model };
            nextModel.children[index] = event.data;
            this.$emit("child-update", { data: nextModel });
        },
        onChildDrag(event) {
            this.$emit("child-drag", { data: event.data });
        },
        emitDrag() {
            this.inDrag = true;
            setTimeout(() => {
                this.$emit("child-drag", { data: this.depth === 1 ? 'section' : 'component' });
            }, 0);
        },
        onMoveStart(event) {
            this.emitDrag();
            event.dataTransfer.setData("text/plain", JSON.stringify(this.model));
            event.dataTransfer.effectAllowed = "move";
            window.dataTransferDelete = () => {
                this.$emit("child-delete", { data: this.index });
            };
        },
        onCloneStart(event) {
            this.emitDrag();
            event.dataTransfer.setData("text/plain", JSON.stringify(this.model));
            event.dataTransfer.effectAllowed = "copy";
        },
        onDragEnd() {
            this.inDrag = false;
            this.inSelect = false;
            this.$emit("child-drag", { data: undefined });
            window.dataTransferDelete = undefined;
        },
        onDrop(event, index) {
            const dragData = JSON.parse(event.dataTransfer.getData("text/plain"));
            const dragEffect = event.dataTransfer.effectAllowed;
            if (dragEffect === "move") {
                window.dataTransferDelete();
            }
            const nextModel = { ...this.model };
            nextModel.children = nextModel.children ?? [];
            nextModel.children.splice(index, 0, { ...dragData });
            console.warn("nextModel", nextModel);
            this.$emit("child-update", { data: nextModel });
            this.$emit("child-drag", { data: undefined });
        },
    }
};
</script>

<style scoped>
.bag {
    position: relative;
    --app-color-tool-hover: rgb(63, 63, 255);
    --app-color-node-border: #ccc;
    --app-color-node-hover: #999;
    --app-color-node-root: #999;
    --app-color-node-shadow: #ddd;

    --app-color-tool-border: #ccc;
    --app-color-tool-background: #eee;
    --app-color-tool-color: #333;
}

.segment-tools {
    z-index: 1;
    position: absolute;
    top: 0;
    left: calc(100% + 2.1em);
    background-color: #333;
    color: white;
    border-radius: 0.25em;
    width: 6em;
    text-align: center;
    padding: 0.25em 0;
    opacity: 0.5;
    font-size: 0.75em;
}

.tools {
    font-size: 0.8em;
    z-index: 1;
    position: absolute;
    top: calc(-1.1em);
    height: 2em;
    left: calc(50% - 8em);
    width: 16em;
    text-align: center;
}

.tools span:last-child {
    margin-right: 0em;
}

.tools span {
    display: inline-block;
    height: 2.25em;
    width: 2.25em;
    margin-right: 0.5em;
    cursor: pointer;
    padding: 0.25em;
    border-radius: 50%;
    background: var(--app-color-tool-background);
    color: var(--app-color-tool-color);
    border: 1px solid var(--app-color-tool-border);
}

.tools span:hover {
    border: 1px solid var(--app-color-tool-hover);
}

.mutable .recursive-node {
    border: 1px dashed var(--app-color-node-border);
    padding: 6px;
    border-radius: 8px;
    position: relative;
}

.recursive-node {
    border: 1px solid transparent;
}

.recursive-node {
    /* border: 1px dashed #ccc; */
}

.recursive-node.in-select {
    border: 1px solid #03f;
}

.placeholder {
    padding: 1em;
    border: 1px dashed var(--app-color-node-border);
    border-radius: 8px;
    margin: 0.5em 0;
}
</style>
