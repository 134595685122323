<template>
    <div v-if="this.inCreate" class="overlay canceler" @click="articleCreateCancel">
        <div class="inset">
            <select ref="templateSelect">
                <option v-for="template in templates" :value="template.id">{{ template.alias }}</option>
            </select>
            <ui-button class="hard-margin-left" @click="articleCreateConfirm">Create</ui-button>
            <ui-button class="hard-margin-left canceler" @click="articleCreateCancel">Cancel</ui-button>
        </div>
    </div>
    <Page class="wide-page">
        <div class="panel">
            <div class="window-content">
                <div class="columns">
                    <div class="lefted">
                        <h3>Articles ({{ hasLoaded ? articles.length : '...' }})</h3>
                    </div>
                    <div class="righted">
                        <ui-button class="iconed" @click="articleCreate">
                            <font-awesome-icon icon="file-plus" />New
                        </ui-button>
                        <ui-button class="iconed margin-left" @click="articlesFetch">
                            <font-awesome-icon icon="sync" />Update
                        </ui-button>
                    </div>
                </div>
                <table v-if="hasLoaded">
                    <thead>
                        <tr>
                            <td class="updatedOn">updated</td>
                            <td class="id">id</td>
                            <td class="alias">alias</td>
                            <td class="actions">&nbsp;</td>
                        </tr>
                    </thead>
                    <tr v-if="articles.length > 0" v-for="article in articles" :key="article.id">
                        <template v-if="article.deleting">
                            <td class="id" colspan="5">
                                <ui-loading></ui-loading>
                            </td>
                        </template>
                        <template v-else>
                            <td class="updatedOn">
                                {{
                                    new Date().getDate() ==
                                        new Date(article.updatedOn).getDate()
                                        ? new Date(
                                            article.updatedOn
                                        ).toLocaleTimeString()
                                        : new Date(
                                            article.updatedOn
                                        ).toLocaleDateString()
                                }}
                            </td>
                            <td class="id">
                                <span class="uuid">{{ article.id }}</span>
                            </td>
                            <td class="alias">{{ article.alias }}</td>
                            <td class="actions">
                                <p>
                                    <ui-button class="margin-left" @click="articleEdit(article.id)">
                                        <font-awesome-icon icon="pencil" />
                                    </ui-button>
                                    <ui-button class="margin-left" @click="articleDelete(article.id)">
                                        <font-awesome-icon icon="trash" />
                                    </ui-button>
                                </p>
                            </td>
                        </template>
                    </tr>
                    <tr v-else>
                        <td class="empty" colspan="5">No Articles</td>
                    </tr>
                </table>
                <Loading v-else></Loading>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from "../layout/Page.vue";
import Loading from "../layout/Loading.vue";

export default {
    name: "ArticleListPage",
    components: {
        Page,
        Loading,
    },
    data() {
        return {
            inCreate: false,
        };
    },
    mounted() {
        this.$store.dispatch("articlesFetch");
        this.$store.dispatch("templatesFetch");
    },
    computed: {
        articles() {
            return this.$store.state.articles;
        },
        hasLoaded() {
            return this.$store.state.articles.loaded;
        },
        templates() {
            return this.$store.state.templates.filter(
                (template) => template.templateType == "Article"
            );
        },
    },
    methods: {
        articleCreate() {
            this.inCreate = true;
        },
        articleCreateConfirm() {
            this.$store.dispatch("articleCreate", {
                templateId: this.$refs.templateSelect.value,
            });
            this.inCreate = false;
        },
        articleCreateCancel(event) {
            if (event.target.classList.contains("canceler")) {
                this.inCreate = false;
            }
        },
        articlesFetch() {
            this.$store.dispatch("articlesFetch");
        },
        articleEdit(id) {
            this.$router.push("/article/" + id);
        },
        articleDelete(id) {
            this.$store.dispatch("articleDelete", id);
        },
    },
};
</script>

<style scoped>
.columns {
    margin-bottom: var(--app-distance-050);
}
.actions a {
    margin: 0 var(--app-distance-025);
}
td.id,
td.empty,
td.updatedOn {
    text-align: center;
}
td.actions {
    text-align: right;
}
td.actions button {
    margin-left: 0.25em;
}

td.updatedOn {
    width: 8em;
}
td.id {
    width: 9em;
}
td.actions {
    width: 10em;
}
div.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    cursor: pointer;
}
div.overlay div.inset {
    padding: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    background-color: #fff;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
div.overlay div.inset > button {
    margin-left: 0.25em;
}
div.overlay div.inset > select {
    width: 8em;
    margin-left: 0;
}
</style>

