<template>
    <Page class="article-edit-page">
        <template v-slot:start>
            <!-- <SideNavigation></SideNavigation> -->
            <SideNavigation></SideNavigation>
            <!-- <UiSlideOut :open="true" direction="left" width="10em">
                <div class="left-slide-out">
                    <ul>
                        <li>
                            <a href="#">
                                <i class="fas fa-home"></i>
                                Component
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fas fa-user"></i>
                                Profile
                            </a>
                        </li>
                    </ul>
                </div>
            </UiSlideOut>
            <UiSlideOut :open="true" direction="left" offset="10em">
                <div class="left-slide-out">
                    qwe
                </div>
            </UiSlideOut> -->
            <UiSlideOut :open="this.selected !== undefined" direction="right">
                <div class="right-slide-out">
                    <div class="tools">
                        <ui-button @click="cancelSelect">
                            <font-awesome-icon icon="xmark" />
                        </ui-button>
                    </div>
                    <div class="property-panel">
                        <label>Type</label><span>{{ this.selected?.data?.type }}</span>
                        <label class="title">Properties</label>
                        <label>style</label>
                        <input :value="this.selected?.data?.properties?.style ?? ''"
                            @change="event => onPropertyChange('style', event)" />
                        <!-- <template v-for="(value, key) in this.selected?.data?.properties">
                            <template v-if="key === 'data'">
                                <label>{{ key }}</label>
                                <input :value="value.value" @change="event => onPropertyChange(key, event)" />
                            </template>
                            <template v-else-if="key !== 'style'">
                            <template v-if="key === 'style'">
                                <label>{{ key }}</label>
                                <input :value="value" @change="event => onPropertyChange(key, event)" />
                            </template>
                        </template> -->
                    </div>
                </div>
            </UiSlideOut>
        </template>
        <div class="panel window-panel">
            <div class="window-header">
                <div class="L">
                    <ui-button @click="undo" :disabled="!(this.draftIndex > 0)">
                        <font-awesome-icon icon="undo" />
                    </ui-button>
                    <ui-button @click="redo" :disabled="!(redos > 0)">
                        <font-awesome-icon icon="redo" />
                    </ui-button>
                </div>
                <div class="C">{{ this.alias && this.alias.length > 63 ? this.alias.substring(0, 63) : this.alias }}
                </div>
                <div :class="this.status + ' R'">
                    <span class="success-label">
                        <font-awesome-icon :icon="
                            this.status === 'saved'
                                ? 'cloud-upload-alt'
                                : 'sync'
                        " />
                        {{ this.status }}
                    </span>
                </div>
            </div>
            <div class="window-content article">
                <recursive-render :model="this.children" :dragging="this.$store.state.dragging"
                    :mutable="true" @child-select="onChildSelect" @child-drag="onChildDrag"
                    @child-update="onChildUpdate" />
            </div>
        </div>
    </Page>
</template>

<script>
import { RecursiveRender, UiSlideOut } from "@common";
import DragTarget from "./side/DragTarget.vue";
import Loading from "../layout/Loading.vue";
import Page from "../layout/Page.vue";
import SideNavigation from "./side/SideNavigation.vue";

export default {
    name: "ArticleEditPage",
    components: {
        Loading,
        Page,
        RecursiveRender,
        SideNavigation,
        DragTarget,
        UiSlideOut,
    },
    mounted() {
        const articleId = this.$route.params.articleId;
        this.$store.dispatch("articleFetch", articleId);
    },
    methods: {
        cancelSelect: function() {
            this.selected.cancelSelection();
            this.selected = undefined;
        },
        onPropertyChange: function (key, event) {
            console.warn("onPropertyChange: key: ", key)
            console.warn("onPropertyChange: event: ", event)
            this.selected.updateProperty(key, event.target.value);
        },
        onChildSelect: function (event) {
            this.selected = event;
        },
        onChildDrag: function (event) {
            this.$store.commit("setDragging", event.data);
        },
        onChildUpdate: function (event) {
            const articleId = this.$route.params.articleId;
            this.$store.dispatch("articleDraftPushSave", {
                id: articleId,
                alias: this.article.alias,
                version: this.article.version,
                content: event.data,
            });
        },
        redo() {
            const id = this.$route.params.articleId;
            this.$store.dispatch("articleDraftNext", { id });
        },
        undo() {
            const id = this.$route.params.articleId;
            this.$store.dispatch("articleDraftPopSave", { id });
        },
        onPublish() {
            const id = this.$route.params.articleId;
            this.$store.dispatch("articleDraftPushSave", {
                id: id,
                alias: this.article.alias,
                version: (parseInt(this.article.version) + 1).toString() + '.0',
                content: this.article.content,
            });
        }
    },
    computed: {
        status: function () {
            return (
                this.$store.state.articles[this.articleId]?.status ?? "-"
            );
        },
        alias: function () {
            const id = this.$route.params.articleId;
            return this.$store.state.articles[id]?.head?.alias ?? "";
        },
        version: function () {
            const id = this.$route.params.articleId;
            return this.$store.state.articles[id]?.head?.version ?? "";
        },
        draftCount: function () {
            const id = this.$route.params.articleId;
            return this.$store.state.articles[id]?.drafts?.length ?? 0;
        },
        draftIndex: function () {
            const id = this.$route.params.articleId;
            return this.$store.state.articles[id]?.draftIndex ?? 0;
        },
        redos: function () {
            return this.draftCount - 1 - this.draftIndex;
        },
        articleId: function () {
            return this.$route.params.articleId;
        },
        article: {
            get() {
                const articleId = this.articleId;
                return this.$store.state.articles[articleId].head;
            }
        },
        children: {
            get() {
                const articleId = this.articleId;
                if (
                    this.$store.state.articles &&
                    this.$store.state.articles[articleId] &&
                    this.$store.state.articles[articleId].head &&
                    this.$store.state.articles[articleId].head.content
                ) {
                    return this.$store.state.articles[articleId].head.content;
                } else {
                    return {};
                }
            },
            set(children) {
                const articleId = this.$route.params.articleId;
                const nextArticle = {
                    ...this.$store.state.articles[articleId].head,
                };
                nextArticle.version = "draft";
                nextArticle.children = children;
                this.$store.dispatch("articleDraftPushSave", {
                    id: articleId,
                    article: nextArticle,
                });
            },
        },
    },
    data() {
        return {
            selected: undefined,
        };
    },
};
</script>

<style scoped>
.thumbnail {
    height: 2em;
}

table {
    border-collapse: collapse;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered img {
    margin-right: var(--app-distance-050);
}

.saved .label {
    padding: var(--app-distance-025) var(--app-distance-050);
    border-radius: var(--app-distance-050);
    border: 1px solid var(--app-color-grey-1);
    background-color: var(--app-color-grey-9);
    color: var(--app-color-grey-1);
}

.saved .success-label {
    padding: var(--app-distance-025) var(--app-distance-050);
    border-radius: var(--app-distance-050);
    border: 1px solid var(--app-color-success-1);
    background-color: var(--app-color-success-8);
    color: var(--app-color-success-1);
}

.saving .label {
    padding: var(--app-distance-025) var(--app-distance-050);
    border-radius: var(--app-distance-050);
    border: 1px solid var(--app-color-grey-1);
    background-color: var(--app-color-grey-9);
    color: var(--app-color-grey-1);
}

.saving .success-label {
    padding: var(--app-distance-025) var(--app-distance-050);
    border-radius: var(--app-distance-050);
    border: 1px solid var(--app-color-warning-1);
    background-color: var(--app-color-warning-8);
    color: var(--app-color-warning-1);
}

.window-content {
    padding: 2em;
}

.left-slide-out,
.right-slide-out {
    padding: 1em;
}

.right-slide-out .tools {
    z-index: 10000;
    text-align: right;
    margin-bottom: -1.5em;
}

.right-slide-out .property-panel {
    display: grid;
    grid-template-columns: 0.5fr auto;
    gap: 1em;
}

.right-slide-out .property-panel label {
    font-weight: bold;
}

.right-slide-out .property-panel label.title {
    grid-column: 1 / span 2;
    border-bottom: 1px solid #ccc;
    margin-top: 0.5em;
}
</style>
