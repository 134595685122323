<template>
    <div :class="'side-navigation' + (this.$store.state.dragging ? ' dragging' : '')">
        <div class="T0">
            <ul>
                <li v-for="{ id, name, icon } in this.categories" @click="() => select(id)"
                    :class="this.selected === id ? 'selected' : 'active'">
                    <font-awesome-icon size="2x" :icon="icon" /> {{ name }}
                </li>
            </ul>
        </div>
        <ui-slide-out direction="left" offset="90px" width="260px" :open="!!this.selected">
            <div class="slide-out-panel">
                <ais-instant-search class="search-region" v-if="this.selected" index-name="cloudkb.template.common"
                    v-bind:search-client="searchClient">
                    <!-- <ais-search-box placeholder="Search here…" class="searchbox" /> -->

                    <ais-hierarchical-menu
                        class="navigation"
                        :key="this.selected"
                        :root-path="this.selected"
                        :attributes="['categories.lvl0', 'categories.lvl1']">
                    </ais-hierarchical-menu>

                    <ais-hits class="results">
                        <template v-slot="{ items }">
                            <ul class="T2">
                                <li
                                    v-for="item in items"
                                    :key="item.objectID"
                                    draggable="true"
                                    v-on:dragstart="(e) => handleDragStart(e, item.template)"
                                    v-on:dragend="handleDragEnd"
                                    class="search-result">
                                    {{ item.description }}
                                </li>
                            </ul>
                        </template>
                    </ais-hits>

                </ais-instant-search>
            </div>
        </ui-slide-out>

        <!-- <div class="T1" :class="this.selected ? 'open' : 'closed'">
            <ul v-if="this.selected">
                <li v-for="{ id, name, icon } in this.selectedCategories" @click="() => subSelect(id)">
                    <font-awesome-icon size="2x" :icon="icon" /> {{ name }}
                </li>
            </ul>
        </div>
        <div class="T2" :class="this.subSelected ? 'open' : 'closed'">
            <ul v-if="this.subSelected">
                <li v-for="{ id, name, icons, template } in this.selectedTemplates"
                    v-on:dragstart="(e) => handleDragStart(e, template, id)" v-on:dragend="handleDragEnd" draggable="true">
                    <div class="bag">
                        <font-awesome-icon v-for="icon in icons" size="2x" :icon="icon" />
                    </div>
                    {{ name }}
                </li>
            </ul>
        </div> -->
    </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { UiSlideOut } from "@common";

const columnTemplate = (columns) => {
    const template = {
        type: 'div',
        properties: {
            style: `display: grid; gap: 8px; grid-template-columns: repeat(${columns}, minmax(0, 1fr))`,
            data: {
                dragMask: 'column'
            }
        },
        children: []
    };
    for (let i = 0; i < columns; i++) {
        template.children.push({
            type: "div",
            properties: {
                style: "flex-grow: 1;",
            },
            children: []
        })
    }
    return template;
};

export default {
    name: 'SideNavigation',
    components: {
        UiSlideOut,
    },
    data() {
        return {
            searchClient: algoliasearch("SZJOES7O66", "4ab4723bc0d027c07bcfcb5fbad1cc9e"),
            categories: [
                {
                    id: "component",
                    name: "Component",
                    icon: "diagram-cells",
                },
                {
                    id: "asset",
                    name: "Asset",
                    icon: "image",
                },

            ],
            cats: [
                {
                    id: "section",
                    name: "Section",
                    icon: "diagram-cells",
                    categories: [
                        {
                            id: "empty",
                            name: "Empty",
                            icon: "square-dashed",
                            templates: [
                                {
                                    id: "empty-section",
                                    name: "Empty",
                                    icons: ["square-dashed"],
                                    template: {
                                        type: 'div',
                                        children: []
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    id: "component",
                    name: "Component",
                    icon: "grid-2-plus",
                    categories: [
                        {
                            id: "columns",
                            name: "Columns",
                            icon: "columns-3",
                            templates: [
                                {
                                    id: "columns-1",
                                    name: "1 column",
                                    icons: ['square-1', 'columns-3'],
                                    template: columnTemplate(1),
                                },
                                {
                                    id: "columns-2",
                                    name: "2 columns",
                                    icons: ['square-2', 'columns-3'],
                                    template: columnTemplate(2),
                                },
                                {
                                    id: "columns-3",
                                    name: "3 columns",
                                    icons: ['square-3', 'columns-3'],
                                    template: columnTemplate(3),
                                },
                                {
                                    id: "columns-4",
                                    name: "4 columns",
                                    icons: ['square-4', 'columns-3'],
                                    template: columnTemplate(4),
                                },
                            ]
                        },
                        {
                            id: "headings",
                            name: "Headings",
                            icon: "heading",
                            templates: [
                                {
                                    id: "heading-1",
                                    name: "heading 1",
                                    icons: ['h1'],
                                    template: {
                                        type: "HeadingField",
                                        properties: {
                                            data: {
                                                value: "heading 1",
                                                level: 1,
                                            }
                                        }
                                    }
                                },
                                {
                                    id: "heading-2",
                                    name: "heading 2",
                                    icons: ['h2'],
                                    template: {
                                        type: "HeadingField",
                                        properties: {
                                            data: {
                                                value: "heading 2",
                                                level: 2,
                                            }
                                        }
                                    }
                                },
                                {
                                    id: "heading-3",
                                    name: "heading 3",
                                    icons: ['h3'],
                                    template: {
                                        type: "HeadingField",
                                        properties: {
                                            data: {
                                                value: "heading 3",
                                                level: 3,
                                            }
                                        }
                                    }
                                },
                                {
                                    id: "heading-4",
                                    name: "heading 4",
                                    icons: ['h4'],
                                    template: {
                                        type: "HeadingField",
                                        properties: {
                                            data: {
                                                value: "heading 4",
                                                level: 4,
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            id: "text",
                            name: "Text",
                            icon: "align-justify",
                            templates: [
                                {
                                    id: "text",
                                    name: "text",
                                    icons: ['text', "align-justify"],
                                    template: {
                                        type: "TextField",
                                        properties: {
                                            data: {
                                                value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Gravida in fermentum et sollicitudin ac orci phasellus. Rhoncus dolor purus non enim praesent elementum facilisis."
                                            },
                                        },
                                    },
                                },
                            ],
                        },
                        {
                            id: "image-category",
                            name: "Image",
                            icon: "image",
                            templates: [
                                {
                                    id: "image-template",
                                    name: "image",
                                    icons: ["image"],
                                    template: {
                                        type: "img",
                                        properties: {
                                            style: "width: 100%",
                                            src: "https://data-bucket-cloudkb-edit.s3.us-east-1.amazonaws.com/cyberdyne23/assets/008b7ca5653ff6f275e9e54515903f78.png",
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ],
            selected: undefined,
            subSelected: undefined,
        }
    },
    computed: {
        selectedCategories() {
            return this.selected
                ? this.categories.filter(i => i.id === this.selected)[0].categories
                : undefined;
        },
        selectedTemplates() {
            return this.selected && this.subSelected
                ? this
                    .categories.filter(i => i.id === this.selected)[0]
                    .categories.filter(i => i.id === this.subSelected)[0].templates
                : undefined;
        },
    },
    methods: {
        handleDragStart(e, template, id) {
            const mask = this.selected;
            const serialized = JSON.stringify(template);
            console.debug('serialized drag template', template);
            e.dataTransfer.setData("text/plain", template);
            this.$store.commit('setDragging', mask);
        },
        handleDragEnd() {
            this.$store.commit('setDragging', undefined);
        },
        clearSelections() {
            this.selected = undefined;
            this.subSelected = undefined;
        },
        selectBack() {
            const storeSelected = this.selected;
            this.selected = undefined;
            this.subSelected = undefined;
            setTimeout(() => {
                this.selected = storeSelected;
                this.subSelected = undefined;
            }, 120);
        },
        select(id) {
            if (this.selected === id) {
                this.clearSelections();
            } else if (this.selected) {
                this.clearSelections();
                setTimeout(() => {
                    this.selected = id;
                }, 120);
            } else {
                this.selected = id;
            }
        },
        subSelect(id) {
            if (this.selected === id) {
                this.clearSelections();
            } else if (this.selected || this.subSelected) {
                this.subSelected = undefined;
                setTimeout(() => {
                    this.subSelected = id;
                }, 120);
            } else {
                this.subSelected = id;
            }
        },
    },
}
</script>

<style scoped>
.dragging .T1,
.dragging .T2 {
    opacity: .5;
}

.side-navigation {
    position: fixed;
    padding-top: 75px;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
}

.side-navigation .bag {
    display: flex;
    gap: 8px;
}

.side-navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.side-navigation .T0 li,
.side-navigation .T1 li {
    cursor: pointer;
}

.side-navigation .T2 li {
    cursor: move;
    border: 1px dashed #666;
    border-radius: 0.5em;
    padding: 0.6em 0;
    margin: 0.5em;
}

.side-navigation li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3em;
    margin: 0;
    padding: 1em 0;
    font-size: 0.8em;
}

.side-navigation .T0 {
    z-index: 99;
}

.side-navigation .T1 {
    z-index: 98;
}

.side-navigation .T2 {
    z-index: 97;
}

.side-navigation .T0,
.side-navigation .T1 {
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
}

.side-navigation .T0 {
    background-color: var(--app-color-afternoon-skyblue-3);
    color: white;
    height: 100%;
    width: 90px;
}

.side-navigation li:hover {
    background-color: var(--app-color-afternoon-skyblue-7a25);
}

.side-navigation .T0 li:hover {
    background-color: var(--app-color-afternoon-skyblue-7a25);
}

.side-navigation .T0 li.selected {
    background: var(--app-color-afternoon-skyblue-6);
    color: white;
}

.side-navigation .T1 {
    background-color: white;
    color: var(--app-color-slate-3);
    width: 90px;
    transition: margin 200ms;
}

.side-navigation .closed {
    margin-left: -100%;
}

.side-navigation .open {
    margin-left: 0%;
}

.slide-out-panel {
    padding: 1em;
}
</style>
