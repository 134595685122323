<template>
    <Page class="wide-page">
        <div class="panel">
            <div class="window-content">
                <div class="columns">
                    <div class="lefted">
                        <h3>Article Templates ({{ hasLoaded ? articleTemplates.length : '...' }})</h3>
                    </div>
                    <div class="righted">
                        <ui-button class="iconed" @click="templatesFetch">
                            <font-awesome-icon icon="sync" />Update
                        </ui-button>
                    </div>
                </div>
                <table v-if="hasLoaded">
                    <thead>
                        <tr>
                            <td class="updatedOn">UpdatedOn</td>
                            <td class="id">Id</td>
                            <td class="alias">Alias</td>
                            <td class="actions">Actions</td>
                        </tr>
                    </thead>
                    <tr
                        v-if="articleTemplates.length > 0"
                        v-for="template in articleTemplates"
                        :key="template.id"
                    >
                        <template v-if="template.deleting">
                            <td class="id" colspan="5">
                                <ui-loading></ui-loading>
                            </td>
                        </template>
                        <template v-else>
                            <td class="updatedOn">
                                {{
                                    new Date().getDate() ==
                                        new Date(template.updatedOn).getDate()
                                        ? new Date(
                                            template.updatedOn
                                        ).toLocaleTimeString()
                                        : new Date(
                                            template.updatedOn
                                        ).toLocaleDateString()
                                }}
                            </td>
                            <td class="id">
                                <span class="uuid">{{ template.id }}</span>
                            </td>
                            <td class="alias">{{ template.alias }}</td>
                            <td class="actions">
                                <p>
                                    <ui-button class="margin-left" @click="templateDelete(template.id)">
                                        <font-awesome-icon icon="trash" />
                                    </ui-button>
                                </p>
                            </td>
                        </template>
                    </tr>
                    <tr v-else>
                        <td class="empty" colspan="4">No Article Templates</td>
                    </tr>
                </table>
                <Loading v-else></Loading>
            </div>
        </div>
        <div class="panel">
            <div class="window-content">
                <div class="columns">
                    <div class="lefted">
                        <h3>Segment Templates ({{ hasLoaded ? segmentTemplates.length : '...' }})</h3>
                    </div>
                    <div class="righted"></div>
                </div>
                <table v-if="hasLoaded">
                    <thead>
                        <tr>
                            <td class="updatedOn">UpdatedOn</td>
                            <td class="id">Id</td>
                            <td class="alias">Alias</td>
                            <td class="actions">Actions</td>
                        </tr>
                    </thead>
                    <tr
                        v-if="segmentTemplates.length > 0"
                        v-for="template in segmentTemplates"
                        :key="template.id"
                    >
                        <template v-if="template.deleting">
                            <td class="id" colspan="5">
                                <ui-loading></ui-loading>
                            </td>
                        </template>
                        <template v-else>
                            <td class="updatedOn">
                                {{
                                    new Date().getDate() ==
                                        new Date(template.updatedOn).getDate()
                                        ? new Date(
                                            template.updatedOn
                                        ).toLocaleTimeString()
                                        : new Date(
                                            template.updatedOn
                                        ).toLocaleDateString()
                                }}
                            </td>
                            <td class="id">
                                <span class="uuid">{{ template.id }}</span>
                            </td>
                            <td class="alias">{{ template.alias }}</td>
                            <td class="actions">
                                <p>
                                    <ui-button class="margin-left" @click="templateDelete(template.id)">
                                        <font-awesome-icon icon="trash" />
                                    </ui-button>
                                </p>
                            </td>
                        </template>
                    </tr>
                    <tr v-else>
                        <td class="empty" colspan="4">No Article Templates</td>
                    </tr>
                </table>
                <Loading v-else></Loading>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from "../layout/Page.vue";
import Loading from "../layout/Loading.vue";

export default {
    name: "TemplateListPage",
    components: {
        Page,
        Loading,
    },
    mounted() {
        this.$store.dispatch("templatesFetch");
    },
    computed: {
        articleTemplates() {
            return this.$store.state.templates.filter(
                (template) => template.templateType === "Article"
            );
        },
        segmentTemplates() {
            return this.$store.state.templates.filter(
                (template) => template.templateType !== "Article"
            );
        },
        hasLoaded() {
            return this.$store.state.templates.loaded;
        },
    },
    methods: {
        templateCreate() {
            this.$store.dispatch("templateCreate");
        },
        templatesFetch() {
            this.$store.dispatch("templatesFetch");
        },
        templateEdit(id) {
            this.$router.push("/template/" + id + "/edit");
        },
        templateDelete(id) {
            this.$store.dispatch("templateDeleteAction", id);
        },
    },
};
</script>

<style scoped>
.columns {
    margin-bottom: var(--app-distance-050);
}
.actions a {
    margin: 0 var(--app-distance-025);
}
td {
    text-align: left;
}
td.empty {
    text-align: center;
}
td.updatedOn,
td.id {
    width: 3em;
}
td.alias {
    width: 10em;
}
td.actions {
    text-align: right;
    width: 8em;
}
</style>
