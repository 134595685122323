import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faAlignJustify as fasAlignJustify,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faArrowRotateRight as fasArrowRotateRight,
    faArrowTurnUp as fasArrowTurnUp,
    faBars as fasBars,
    faBooks as fasBooks,
    faBoxOpen as fasBoxOpen,
    faClapperboard as fasClapperboard,
    faClone as fasClone,
    faCloud as fasCloud,
    faCloudUploadAlt as fasCloudUploadAlt,
    faCodeBranch as fasCodeBranch,
    faColumns as fasColumns,
    faColumns3 as fasColumns3,
    faCopy as fasCopy,
    faDiagramCells as fasDiagramCells,
    faEdit as fasEdit,
    faEye as fasEye,
    faFileCircleInfo as fasFileCircleInfo,
    faFilePlus as fasFilePlus,
    faGrid2Plus as fasGrid2Plus,
    faH1 as fasH1,
    faH2 as fasH2,
    faH3 as fasH3,
    faH4 as fasH4,
    faHeading as fasHeading,
    faHome as fasHome,
    faImage as fasImage,
    faLayerGroup as fasLayerGroup,
    faMoon as fasMoon,
    faNewspaper as fasNewspaper,
    faObjectGroup as fasObjectGroup,
    faPalette as fasPalette,
    faPencilAlt as fasPencilAlt,
    faPencilRuler as fasPencilRuler,
    faPhotoFilm as fasPhotoFilm,
    faPlus as fasPlus,
    faRedo as fasRedo,
    faSave as fasSave,
    faSearch as fasSearch,
    faShapes as fasShapes,
    faSignInAlt as fasSignInAlt,
    faSignOutAlt as fasSignOutAlt,
    faSquare1 as fasSquare1,
    faSquare2 as fasSquare2,
    faSquare3 as fasSquare3,
    faSquare4 as fasSquare4,
    faSquareDashed as fasSquareDashed,
    faStepBackward as fasStepBackward,
    faSun as fasSun,
    faSync as fasSync,
    faText as fasText,
    faTrash as fasTrash,
    faUndo as fasUndo,
    faUpload as fasUpload,
    faUsers as fasUsers,
    faWarehouse as fasWarehouse,
    faXmark as fasXmark,
    faLeftRight as fasLeftRight,
    faMinimize as fasMinimize,
    faMaximize as fasMaximize,
} from '@fortawesome/pro-solid-svg-icons';

const buildIcons = () => {
    library.add(
        fasMinimize,
        fasMaximize,
        fasLeftRight,
        fasXmark,
        fasAlignJustify,
        fasAngleLeft,
        fasAngleRight,
        fasArrowRotateRight,
        fasArrowTurnUp,
        fasBars,
        fasBooks,
        fasBoxOpen,
        fasClapperboard,
        fasClone,
        fasCloud,
        fasCloudUploadAlt,
        fasCodeBranch,
        fasColumns,
        fasColumns3,
        fasCopy,
        fasDiagramCells,
        fasEdit,
        fasEye,
        fasFileCircleInfo,
        fasFilePlus,
        fasGrid2Plus,
        fasH1,
        fasH2,
        fasH3,
        fasH4,
        fasHeading,
        fasHome,
        fasImage,
        fasLayerGroup,
        fasMoon,
        fasNewspaper,
        fasObjectGroup,
        fasPalette,
        fasPencilAlt,
        fasPencilRuler,
        fasPhotoFilm,
        fasPlus,
        fasRedo,
        fasSave,
        fasSearch,
        fasShapes,
        fasSignInAlt,
        fasSignOutAlt,
        fasSquare1,
        fasSquare2,
        fasSquare3,
        fasSquare4,
        fasSquareDashed,
        fasStepBackward,
        fasSun,
        fasSync,
        fasText,
        fasTrash,
        fasUndo,
        fasUpload,
        fasUsers,
        fasWarehouse,
    );
    return FontAwesomeIcon;
};

export { buildIcons };
