import { createRouter, createWebHistory } from "vue-router";
import ArticleEditPage from "./components/pages/ArticleEditPage.vue";
import ArticleListPage from "./components/pages/ArticleListPage.vue";
import TemplateListPage from "./components/pages/TemplateListPage.vue";
import AssetListPage from "./components/pages/AssetListPage.vue";
import SignInPage from "./components/pages/SignInPage.vue";

const routes = [
    { path: '/', component: ArticleListPage },
    { path: "/article/:articleId", component: ArticleEditPage },
    { path: '/articles', component: ArticleListPage },
    { path: '/templates', component: TemplateListPage },
    { path: '/assets', component: AssetListPage },
    { path: '/signin', component: SignInPage },
];

const buildRouter = (store) => {
    const router = createRouter({
        history: createWebHistory(),
        routes: routes,
    });
    // router middleware
    router.beforeEach((to, _, next) => {
        const isSignedIn = store.state?.authentication?.isSignedIn === true;
        // redirect non-authenticated to /signin page
        if (!isSignedIn && to.fullPath !== "/signin") {
            return next({ path: "/signin" });
        }
        // redirect / to /articles
        if (isSignedIn && to.fullPath === "/") {
            return next({ path: "/articles" });
        }
        return next();
    });

    return router;
};

export { buildRouter, routes };
