<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script> 

<style>

@font-face {
  font-family: "cavolini";
  src: local("cavolini"), url(/mock/cavolini.ttf) format("truetype");
}

@font-face {
  font-family: "century_gothic";
  src: local("century_gothic"), url(/mock/century_gothic.ttf) format("truetype");
}


body {
    --app-color-persimmon-3: #944800;
    --app-color-persimmon-4: #bd5c00;
    --app-color-persimmon-5: #ee7400;
    --app-color-persimmon-6: #ff9631;
    --app-color-persimmon-7: #ffac5d;
    --app-color-persimmon-7a50: #ffac5d7f;

    --app-color-amanda-green-3: #5a7000;
    --app-color-amanda-green-4: #7b9900;
    --app-color-amanda-green-5: #97bb00;
    --app-color-amanda-green-6: #c0eb0c;
    --app-color-amanda-green-7: #cff044;
    --app-color-amanda-green-7a25: #cff0443F;

    --blue-5-005: rgba(14, 78, 173, 0.05);
    --blue-5-010: rgba(14, 78, 173, 0.1);
    --blue-5-070: rgba(14, 78, 173, 0.7);
    --blue-5-080: rgba(14, 78, 173, 0.8);
    --blue-5-090: rgba(14, 78, 173, 0.9);
    --blue-5-100: rgba(14, 78, 173, 1);

    --blue-6-005: rgba(14, 78, 173, 0.05);
    --blue-6-070: rgba(30, 113, 236, 0.7);
    --blue-6-100: rgba(30, 113, 236, 1);
}

[data-amplify-authenticator] {
    --amplify-colors-font-interactive: var(--blue-6-100);
    --amplify-components-tabs-item-active-color: var(--blue-6-100);
    --amplify-colors-font-interactive: var(--blue-6-100);
    --amplify-components-tabs-item-active-border-color: var(--blue-6-100);

    --amplify-components-tabs-item-hover-color: var(--blue-6-100);
    --amplify-components-button-link-color: var(--blue-6-100);

    --amplify-components-button-primary-background-color: var(--blue-5-100);
    --amplify-components-button-primary-hover-background-color: var(
        --blue-6-100
    );
    --amplify-components-button-link-hover-background-color: var(--blue-6-005);

    --amplify-components-button-hover-border-color: var(--blue-5-005);
    --amplify-components-button-hover-background-color: var(--blue-5-100);

    --amplify-components-button-focus-border-color: var(--blue-6-005);
    --amplify-components-button-focus-background-color: var(--blue-6-100);
    --amplify-components-button-focus-border-color: var(--blue-6-005);
}

#app .amplify-button:hover,
#app .amplify-button:focus,
#app .amplify-button:active {
    --amplify-components-button-hover-color: var(--blue-5-100);
    --amplify-components-button-hover-border-color: var(--blue-5-070);
    --amplify-components-button-hover-background-color: var(--blue-5-005);
    --amplify-components-button-focus-color: var(--blue-6-100);
    --amplify-components-button-focus-border-color: var(--blue-6-070);
    --amplify-components-button-focus-background-color: var(--blue-6-005);
}

button.margin-left {
    margin-left: 0.25em;
}
button.iconed svg {
    margin: 0 0.5em 0 0;
}
.article-edit-page h1 {
    font-size: 1.66em;
    line-height: 1.66em;
}
</style>
