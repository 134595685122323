<template>
    <div
        v-if="dragMatch"
        :class="inDrag ? 'in-drag' : ''"
        :data-component="this.$options.name"
        @dragexit="inDrag = false"
        @dragend="inDrag = false"
        @dragleave="inDrag = false"
        @drop="inDrag = false"
        @dragover="inDrag = true"
        >&nbsp;</div>
</template>

<script>
export default {
    name: 'DragTarget',
    props: {
        dragMask: {
            type: String,
            default: '.*'
        },
        dragging: {
            type: String,
            default: undefined
        },
    },
    data: () => {
        return {
            inDrag: false
        };
    },
    computed: {
        dragMatch() {
            return this.dragging
                ? this.dragging.match(this.dragMask)
                : false;
        },
    }
}
</script>

<style scoped>
div {
    font-size: 0.6em;
    line-height: 0.6em;
    border: 1px dashed #ccc;
    background-color: rgb(255, 242, 207);
}

.in-drag {
    background-color: rgb(226, 255, 179);
}
</style>
