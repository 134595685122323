import { Environment } from "@common";
const { API_GATEWAY_URL } = Environment;

const getArticles = async (token) => {
    const url = `https://${API_GATEWAY_URL}/article`;
    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const deleteArticle = async (token, articleId) => {
    const url = `https://${API_GATEWAY_URL}/article/${articleId}`;
    const response = await fetch(url, {
        method: "DELETE",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const getArticle = async (token, articleId) => {
    const url = `https://${API_GATEWAY_URL}/article/${articleId}`;
    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const saveArticle = async (token, article) => {
    if (!article.id || !article.alias || !article.version || !article.content) {
        throw new Error("article must have id, alias, version and content");
    }

    const url = `https://${API_GATEWAY_URL}/article`;
    const response = await fetch(url, {
        method: "POST",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
            id: article.id,
            alias: article.alias,
            version: article.version,
            content: article.content
        })
    });
    return response.json();
};

const getTemplates = async (token) => {
    const url = `https://${API_GATEWAY_URL}/template`;
    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const getTemplate = async (token, templateId) => {
    const url = `https://${API_GATEWAY_URL}/template/${templateId}`;
    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const deleteTemplate = async (token, templateId) => {
    const url = `https://${API_GATEWAY_URL}/template/${templateId}`;
    const response = await fetch(url, {
        method: "DELETE",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const getAssets = async (token) => {
    const url = `https://${API_GATEWAY_URL}/asset`;
    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

const deleteAsset = async (token, assetId) => {
    const url = `https://${API_GATEWAY_URL}/asset/${assetId}`;
    const response = await fetch(url, {
        method: "DELETE",
        redirect: 'follow',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.json();
};

export {
    getArticles,
    getArticle,
    saveArticle,
    deleteArticle,
    getTemplates,
    getTemplate,
    deleteTemplate,
    getAssets,
    deleteAsset,
};
