import { Amplify } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';
import { Authenticator, AmplifyButton } from '@aws-amplify/ui-vue';
import { createApp } from 'vue';
import { Hub } from 'aws-amplify';
import { Environment } from "@common";
import { UiLogo, UiButton, UiLoading } from '@common';
import Editor from '@tinymce/tinymce-vue';
import InstantSearch from 'vue-instantsearch/vue3/es';

import '@aws-amplify/ui-vue/styles.css';

import { buildIcons } from './buildIcons';
import { buildRouter } from './buildRouter';
import { buildStore } from './buildStore';
import App from './components/App.vue';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: Environment.STACK_REGION,
        userPoolId: Environment.USER_POOL_ID,
        userPoolWebClientId: Environment.USER_POOL_CLIENT_ID,
    },
});

const store = buildStore();
const router = buildRouter(store);
const icons = buildIcons();

Auth.currentAuthenticatedUser()
    .then(user => store.commit('userInitialize', user))
    .catch(err => { /*  ignore user is not authenticated */ });

Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'signIn') {
        store.commit('userSignIn', { username: data.username });
        router.push('/');
    } else if (event === 'signOut') {
        store.commit('userSignOut');
        router.push('/signin');
    }
});

createApp(App)
    .component('ui-logo', UiLogo)
    .component('ui-button', UiButton)
    .component('ui-loading', UiLoading)
    .component('font-awesome-icon', icons)
    .component('amplify-authenticator', Authenticator)
    .component('amplify-button', AmplifyButton)
    .component("editor", Editor)
    .use(InstantSearch)
    .use(store)
    .use(router)
    .mount('#app');
