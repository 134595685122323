<template>
    <template v-if="mutable && this.inEdit">
        <div class="field-pair">
            <input 
                v-on:blur="save"
                ref="inputValue" type="text" v-model="this.data.value" @keyup.enter="save" />
            <select ref="inputLevel" v-model="this.data.level" @change="save">
                <option v-for="level in [1, 2, 3, 4, 5, 6]" :value="level">h{{ level }}</option>
            </select>
        </div>
    </template>
    <component v-else :is="this.tag" :class="mutable ? 'editable' : ''" @click="() => inEdit = true">
        {{ this.data.value }}
    </component>
</template>

<script>
import UiButton from "../ui/UiButton.vue";

export default {
    name: "HeadingField",
    components: {
        UiButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: { value: "", level: 1 },
        },
        mutable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["field-update"],
    data() {
        return {
            inEdit: false,
        };
    },
    methods: {
        edit() {
            this.inEdit = true;
        },
        save() {
            const data = {
                value: this.$refs.inputValue.value,
                level: parseInt(this.$refs.inputLevel.value),
            };
            console.debug("HeadingField.save", data);
            this.$emit("field-update", {
                data,
            });
            this.inEdit = false;
        },
    },
    computed: {
        tag() {
            return `h${this.data.level}`;
        },
    },
};
</script>

<style scoped>
.editable {
    cursor: pointer;
}

.field-pair {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    margin: 0.5em 0;
}

select {
    font-size: 16px;
    padding: 0.75em 0.5em;
    border-radius: 0.25em;
    background-color: white;
}

input {
    font-size: 16px;
    padding: 0.75em 0.5em;
    flex-grow: 1;
    border-radius: 0.25em;
    background-color: white;
}
</style>
