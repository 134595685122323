<template>
    <div class="page-root">
        <Header id="header"></Header>
        <div v-if="$slots.subheader">
            <div id="subheader-container">
                <slot name="subheader">&nbsp;</slot>
            </div>
        </div>
        <div v-if="$slots.start">
            <slot name="start">&nbsp;</slot>
        </div>
        <div id="top-rail" v-if="$slots.top">
            <slot name="top">&nbsp;</slot>
        </div>
        <div id="body">
            <div id="left-rail" v-if="$slots.left">
                <slot name="left"></slot>
            </div>
            <div id="main">
                <slot></slot>
            </div>
            <div id="right-rail" v-if="$slots.right">
                <slot name="right"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../layout/Header.vue";

export default {
    name: "Page",
    components: {
        Header,
    },
};
</script>

<style>
#subheader-container {
    display: flex;
    font-size: 14px;
}

.panel {
    margin-top: 16px;
}

.window-panel {
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.window-panel .window-header {
    border-bottom: 1px solid #ddd;
    min-width: 600px;
    max-width: 1080px;
    padding: 8px;
    margin: 0 auto 0 auto;
    background: linear-gradient(0deg, #eee 0%, #fff 20%, #fff 30%, #eee 100%);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.window-panel .window-header .L,
.window-panel .window-header .C,
.window-panel .window-header .R {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.window-panel .amplify-button {
    padding: 4px;
    font-size: 14px;
}

.window-panel .window-header .L {
    justify-content: flex-start;
    gap: 8px;
}

.window-panel .window-header .C {
    justify-content: center;
    padding: 8px 0;
}

.window-panel .window-header .R {
    justify-content: flex-end;
}

.window-content {
    padding: 16px;
}


.window-panel .window-header button {
    background: white;
    padding: 0.5em 0.75em;
}
.window-panel .window-header .R {
    padding-right: 1em;
}
.window-panel .window-header .L {
    padding-left: 1em;
}
.window-panel .window-header .R .label,
.window-panel .window-header .R button {
    background-color: white;
    margin-right: 0.5em;
}
.window-panel .window-header .L button {
    margin-left: 0.5em;
}

.window-panel .article h1 {
    font-size: 1.75em;
}
.window-panel .article h2 {
    font-size: 1.50em;
}
.window-panel .article h3 {
    font-size: 1.3em;
}
.window-panel .article h4,
.window-panel .article h5,
.window-panel .article h6 {
    font-size: 1.2em;
}
</style>
