<template>
    <div
        :data-component="this.$options.name"
        :style="this.columnsTemplate">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ColumnField",
    props: {
        columns: {
            type: Number,
            default: 1,
        },
        inDrag: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        expanded() {
            return `minmax(0, 0.5em) repeat(${this.columns}, minmax(0, 1fr) minmax(0, 0.5em))`;
        },
        collapsed() {
            return `repeat(${ this.columns }, minmax(0, 1fr))`;
        },
        columnsTemplate() {
            const prefix = `display: grid; gap: 0.5em`;
            return this.inDrag 
             ? `${prefix}; grid-template-columns: ${this.expanded};`
             : `${prefix}; grid-template-columns: ${this.collapsed};`;
        },
    },
};
</script>
