<script>
import logo from '../assets/logo.svg';

export default {
    name: 'UiLogo',
    props: {
        height: {
            type: Number,
            default: 80
        },
    },
    data() {
        return {
            logo,
        };
    },
};
</script>

<template>
    <img :src="logo" :height="height" />
</template>
