const STACK_NAME = import.meta.env.VITE_STACK_NAME || "not found in env";
const STACK_REGION = import.meta.env.VITE_STACK_REGION || "not found in env";
const USER_POOL_ID = import.meta.env.VITE_USER_POOL_ID || "not found in env";
const USER_POOL_CLIENT_ID = import.meta.env.VITE_USER_POOL_CLIENT_ID || "not found in env";
const API_GATEWAY_URL = import.meta.env.VITE_API_GATEWAY_URL || "not found in env";
const DATA_BUCKET_NAME = import.meta.env.VITE_DATA_BUCKET_NAME || "not found in env";

export const Environment = {
    STACK_NAME,
    STACK_REGION,
    USER_POOL_ID,
    USER_POOL_CLIENT_ID,
    API_GATEWAY_URL,
    DATA_BUCKET_NAME,
};
