<template>
    <Page class="wide-page">
        <div class="panel">
            <div class="window-content">
                <div class="columns">
                    <div class="lefted">
                        <h3>Assets ({{ hasLoaded ? assets.length : '...' }})</h3>
                    </div>
                    <div class="righted">
                        <ui-button class="iconed" @click="assetsFetch">
                            <font-awesome-icon icon="sync" />Update
                        </ui-button>
                    </div>
                </div>
                <table v-if="hasLoaded">
                    <thead>
                        <tr>
                            <td class="updatedOn">UpdatedOn</td>
                            <td class="id">Id</td>
                            <td class="alias">Alias</td>
                            <td class="thumbnail">Thumbnail</td>
                            <td class="actions">Actions</td>
                        </tr>
                    </thead>
                    <tr v-if="assets.length > 0" v-for="asset in assets" :key="asset.id">
                        <template v-if="asset.deleting">
                            <td class="id" colspan="5">
                                <ui-loading></ui-loading>
                            </td>
                        </template>
                        <template v-else>
                            <td class="updatedOn">
                                {{
                                    new Date().getDate() ==
                                        new Date(asset.updatedOn).getDate()
                                        ? new Date(
                                            asset.updatedOn
                                        ).toLocaleTimeString()
                                        : new Date(
                                            asset.updatedOn
                                        ).toLocaleDateString()
                                }}
                            </td>
                            <td class="id">
                                <span class="uuid">{{ asset.id }}</span>
                            </td>
                            <td class="alias">{{ asset.alias }}</td>
                            <td class="thumbnail">
                                <img
                                    style="max-width: 6em"
                                    :src="`https://${this.bucket}.s3.us-east-1.amazonaws.com/${asset.contentPath}`"
                                />
                            </td>
                            <td class="actions">
                                <p>
                                    <ui-button class="margin-left" @click="assetDelete(asset.id)">
                                        <font-awesome-icon icon="trash" />
                                    </ui-button>
                                </p>
                            </td>
                        </template>
                    </tr>
                    <tr v-else>
                        <td class="empty" colspan="5">No Assets</td>
                    </tr>
                </table>
                <Loading v-else></Loading>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from "../layout/Page.vue";
import Loading from "../layout/Loading.vue";
import { Environment } from "@common";

export default {
    name: "assetListPage",
    components: {
        Page,
        Loading,
    },
    mounted() {
        this.$store.dispatch("assetsFetch");
    },
    data() {
        return {
            bucket: Environment.DATA_BUCKET_NAME,
        };
    },
    computed: {
        assets() {
            return this.$store.state.assets;
        },
        hasLoaded() {
            return this.$store.state.assets.loaded;
        },
    },
    methods: {
        assetCreate() {
            this.$store.dispatch("assetCreate");
        },
        assetsFetch() {
            this.$store.dispatch("assetsFetch");
        },
        assetEdit(id) {
            this.$router.push("/asset/" + id + "/edit");
        },
        assetDelete(id) {
            this.$store.dispatch("assetDeleteAction", id);
        },
    },
};
</script>

<style scoped>
.columns {
    margin-bottom: var(--app-distance-050);
}

.actions a {
    margin: 0 var(--app-distance-025);
}

td {
    text-align: left;
}

td.updatedOn,
td.id {
    width: 5em;
}

td.alias {
    width: 10em;
}

td.actions {
    text-align: right;
    width: 8em;
}

td.empty,
td.thumbnail {
    text-align: center;
}
</style>
