import { Auth } from "aws-amplify";
import cloneDeep from 'lodash.clonedeep';
import { createStore } from "vuex";
import { Environment } from "@common";

import {
    getArticle,
    getArticles,
    deleteArticle,
    saveArticle,
    getTemplates,
    getTemplate,
    deleteTemplate,
    getAssets,
    deleteAsset,
} from "./api";

const actions = {};
const mutations = {};

const addAction = (actionName, action) => {
    if (actions[actionName]) {
        throw new Error(`Duplicate action name: ${actionName}`);
    }
    actions[actionName] = action;
};

const addMutation = (mutationName, mutation) => {
    if (mutations[mutationName]) {
        throw new Error(`Duplicate mutation name: ${mutationName}`);
    }
    mutations[mutationName] = mutation;
};

addAction("articleCreate", async ({ commit, state }, { templateId }) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    await getTemplate(idToken, templateId).then(async ({ template }) => {
        console.debug("received template", template);
        const alias = `${template.alias} ${new Date().toISOString()}`;
        const article = {
            alias: alias,
            content: template.content,
        };
        article.content.version = "0.0";
        article.content.alias = alias;

        await saveArticle(idToken, article).then(function () {
            console.warn("saved article", article);
        });
        console.warn(`got template (${templateId})`, template);
    });
});

addAction("articleDraftPushSave", async ({ commit }, { id, alias, version, content }) => {
    const article = { id, alias, version, content };

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("articleDraftPush", article);
    await saveArticle(idToken, article)
    .then(function () {
        commit("articleMarkSaved", {
            id,
        });
    });
});

addMutation("articleCreateMutation", (state, templateId) => {
    state.articles.push({
        loading: true
    });
});

addAction("articlesFetch", async ({ commit }) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("articlesClearMutation");
    getArticles(idToken).then(articles => {
        commit("articlesSetMutation", articles.articles);
    });
});

addAction("articleDelete", async ({ commit }, articleId) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("articleMarkMutation", articleId);
    await deleteArticle(idToken, articleId);
    getArticles(idToken).then(articles => {
        commit("articlesSetMutation", articles.articles);
    });
});

addMutation("articlesSetMutation", (state, articles) => {
    state.articles = articles;
    state.articles.loaded = true;
});

addMutation("articleMarkMutation", (state, articleId) => {
    state.articles.filter(article => article.id === articleId)[0].deleting = true;
});

addMutation("articlesClearMutation", (state) => {
    state.articles = { loaded: false };
});

addAction("articleFetch", async ({ commit }, articleId) => {

    const decodeJsonEscapes = (content) => {
        const clondedContent = cloneDeep(content);
        if (clondedContent) {
            if (clondedContent.children && clondedContent.children.length > 0) {
                clondedContent.children.forEach(child => {
                    decodeJsonEscapes(child);
                });
            } else if (clondedContent.type === "TextField" && clondedContent.props && clondedContent.props.data) {
                clondedContent.props.data = clondedContent.props.data
                    .replace(/[\"]/g, '"')
                    .replace(/[\n]/g, '')
                    .replace(/(<h1.*>)([\s\S]*?)(<\/h1>)/g, ''); // remove h1 headers
            }
        }
        return clondedContent;
    };

    commit("articleClear", articleId);
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    getArticle(idToken, articleId).then(function (response) {
        const article = {
            id: response.article.id,
            alias: response.article.alias,
            version: response.article.version,
            content: decodeJsonEscapes(response.article.content),
        };
        commit("articleDraftPush", article);
        commit("articleMarkSaved", {
            id: articleId,
        });
    });
});

addAction("articleDraftPopSave", async ({ commit, state }, { id }) => {
    const article = state.articles[id].drafts[state.articles[id].draftIndex - 1];
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("articleDraftPop", {
        id,
    });
    await saveArticle(idToken, article)
    .then(function () {
        commit("articleMarkSaved", {
            id,
        });
    });
});

addAction("articleDraftNext", async ({ commit, state }, { id }) => {
    const article = state.articles[id].drafts[state.articles[id].draftIndex - 1];
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("articleDraftNext", {
        id,
    });
    await saveArticle(idToken, {
        content: article
    }).then(function () {
        commit("articleMarkSaved", {
            id,
        });
    });
});

addMutation("articleClear", (state, articleId) => {
    if (state?.articles?.[articleId]?.head) {
        state.articles[articleId].content = { children: [] };
    }
});

addMutation("articleMarkSaved", (state, { id }) => {
    state.articles[id].status = "saved";
});

addMutation("articleDraftNext", (state, { id }) => {
    const draftIndex = state.articles[id].draftIndex + 1;
    state.articles[id].draftIndex = draftIndex;
    state.articles[id].head = cloneDeep(state.articles[id].drafts[draftIndex]);
});

addMutation("articleDraftPop", (state, { id }) => {
    if (state.articles[id].draftIndex > 0) {
        const draftIndex = state.articles[id].draftIndex - 1;
        state.articles[id].draftIndex = draftIndex;
        state.articles[id].head = cloneDeep(state.articles[id].drafts[draftIndex]);
    }
});

addMutation("articleDraftPush", (state, article) => {
    const id = article.id;
    if (!state.articles[id]) {
        state.articles[id] = article;
    }
    if (!state.articles[id].drafts) {
        state.articles[id].drafts = [];
    }
    const next = cloneDeep(article);
    state.articles[id].drafts.splice(state.articles[id].draftIndex + 1, 999);
    state.articles[id].drafts.push(next);

    const draftIndex = state.articles[id].drafts.length - 1;
    state.articles[id].draftIndex = draftIndex;
    state.articles[id].head = cloneDeep(state.articles[id].drafts[draftIndex]);
    state.articles[id].status = "saving";
});

addAction("templatesFetch", async ({ commit }) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("templatesClearMutation");
    getTemplates(idToken).then(templates => {
        commit("templatesSetMutation", templates.templates);
    });
});

addAction("templateDeleteAction", async ({ commit }, templateId) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("templateMarkMutation", templateId);
    await deleteTemplate(idToken, templateId);
    getTemplates(idToken).then(templates => {
        commit("templatesSetMutation", templates.templates);
    });
});

addMutation("templatesSetMutation", (state, templates) => {
    state.templates = templates;
    state.templates.loaded = true;
});

addMutation("templateMarkMutation", (state, templateId) => {
    state.templates.filter(template => template.id === templateId)[0].deleting = true;
});

addMutation("templatesClearMutation", (state) => {
    state.templates = { loaded: false };
});

addAction("assetsFetch", async ({ commit }) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("assetsClearMutation");
    getAssets(idToken).then(assets => {
        commit("assetsSetMutation", assets.assets);
    });
});

addAction("assetDeleteAction", async ({ commit }, assetId) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    commit("assetMarkMutation", assetId);
    await deleteAsset(idToken, assetId);
    getAssets(idToken).then(assets => {
        commit("assetsSetMutation", assets.assets);
    });
});

addMutation("assetsSetMutation", (state, assets) => {
    state.assets = assets;
    state.assets.loaded = true;
});

addMutation("assetMarkMutation", (state, assetId) => {
    state.assets.filter(asset => asset.id === assetId)[0].deleting = true;
});

addMutation("assetsClearMutation", (state) => {
    state.assets = { loaded: false };
});

addMutation("userInitialize", (state, authenticatedUser) => {
    state.authentication = { isSignedIn: !!authenticatedUser, username: authenticatedUser.username };
});

addMutation("userSignIn", (state, { username }) => {
    state.authentication = { isSignedIn: true, username };
});

addMutation("userSignOut", (state) => {
    state.authentication = { isSignedIn: false, username: undefined };
});

addMutation("setDragging", (state, value) => {
    state.dragging = value;
});

const buildStore = (authenticatedUser) => createStore({
    state() {
        return {
            aws: {
                USER_POOL_ID: Environment.USER_POOL_ID,
                USER_POOL_CLIENT_ID: Environment.USER_POOL_CLIENT_ID,
            },
            authentication: {
                username: authenticatedUser?.username,
                isSignedIn: !!authenticatedUser,
            },
            articles: [],
            templates: [],
            assets: [],
            dragging: undefined,
        }
    },
    actions: actions,
    mutations: mutations,
});

export { buildStore };
