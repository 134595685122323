<template>
    <div class="container rounded">&nbsp;</div>
</template>

<script>
export default {
    name: "Loading",
};
</script>

<style scoped>
.container {
    border: 1px solid #eee;
    background: #fff;
    height: 1.5em;
    position: relative;
    overflow: hidden;
}
.container::before {
    content: "";
    display: block;
    position: absolute;
    left: -25%;
    top: 0;
    height: 100%;
    width: 25%;
    background: linear-gradient(
        to right,
        transparent 0%,
        #eeeeee 50%,
        transparent 100%
    );
    animation: load 1.5s infinite;
}
@keyframes load {
    from {
        left: -25%;
    }
    to {
        left: 100%;
    }
}
</style>
